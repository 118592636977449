.fr-card__body {
  .card-button{
    position: absolute;
    top: .25rem;
    right: .25rem;
    z-index: 1;
  }
}

.structures-title {
  color: var(--structures-color) !important;
}

.persons-title {
  color: var(--persons-color) !important;
}

.prizes-title {
  color: var(--prizes-color) !important;
}

.projects-title {
  color: var(--projects-color) !important;
}

.terms-title {
  color: var(--terms-color) !important;
}
.categories-title {
  color: var(--categories-color) !important;
}
.legal-categories-title {
  color: var(--legal-categories-color) !important;
}
.structures-border {
  border-left: .25rem solid var(--structures-color) !important;
}

.persons-border {
  border-left: .25rem solid var(--persons-color) !important;
}

.prizes-border {
  border-left: .25rem solid var(--prizes-color) !important;
}

.projects-border {
  border-left: .25rem solid var(--projects-color) !important;
}

.terms-border {
  border-left: .25rem solid var(--terms-color) !important;
}
.categories-border {
  border-left: .25rem solid var(--categories-color) !important;
}
.legal-categories-border {
  border-left: .25rem solid var(--legal-categories-color) !important;
}

.card-body {
  position: relative;
}

.card-end {
  margin-top: 0 !important;
  width: fit-content;
}

.edit-button {
  position: absolute;
  top: .25rem;
  right: .25rem;
}

.blue-border {
  border: 1px solid var(--text-action-high-blue-france) !important;
}
.button-full {
  width: 100% !important;
  height: 100% !important;
}