// HTML {
//   @media only screen and (min-width: 992px) {
//     font-size: 90%;
//   }
// }
HR {
  margin: 24px 0 16px 0;
  padding-bottom: 8px;
}

.ul-styled {
  list-style-type: disc !important;
  > li {
    list-style: inherit;
    margin-bottom: 0;
  }
  padding-left: 1rem !important;
  margin-bottom: 1rem;
}



.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.italic {
  font-style: italic;
}

.fr-background-alt {
  background-color: var(--background-alt-grey);
}
.fullwidth {
  width: 100%;
}

.sidemenu__item--active {
   A:before {
    --idle: transparent;
    --hover: var(--border-active-blue-france-hover);
    --active: var(--border-active-blue-france-active);
    background-color: var(--border-active-blue-france);
    bottom: .75rem;
    content: "";
    left: 0;
    position: absolute;
    top: .75rem;
    width: 2px;
  }
  .fr-text--md {color: var(--text-active-blue-france); }
}

.show-bt-on-over:hover {
  .bt-visible-on-over {
    visibility: visible;
  }
}

.bt-visible-on-over {
  visibility: hidden;
}

.no-span {
  SPAN {
    display: none;
  }
}

.hidden {
  visibility: hidden;
}

.hide {
  display: none;
}

.highlight-border {
  border-color: var(--border-color);
}

.leaflet-control-zoom {
  position: static;
  z-index: 1 !important;
  A {
    background-image: none !important;
  }
}
.leaflet-pane {
  position: static;
  z-index: 40 !important;
}
.leaflet-container {
  position: relative;
  z-index: 1 !important;
}
.leaflet-tooltip-pane {
  position: relative;
}

.leaflet-control {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.edit-button {
  position: absolute;
  top: .25rem;
  right: .25rem;
}

$objects: structures, persons, prizes, terms, projects, categories, legal-categories, supervising-ministers, official-texts, officialtexts;
$sides: left, bottom, right, top;

@each $object in $objects {
  .card-#{$object} {
    --primaryColor: var(--#{$object}-color);
    border-left: .25rem solid var(--primaryColor);
    .fr-card__start {
      .fr-card__detail {
        color: var(--primaryColor) !important;
      }
    }
  }
}
@each $side in $sides {
  .card--border-#{$side} {
    border-left: 0 !important;
    border-#{$side}: .25rem solid var(--primaryColor) !important;
  }
}

.search-bg {
  background: var(--grey-card);
}

.turngrey {
  filter: brightness(70%);
}

.stick {
  position: sticky;
  top: 0;
  background: var(--background-default-grey);
  z-index: 100;
  margin: 0;
  margin-top: 0px;
  margin-left: 0px;
  margin-top: .25rem;
}

.stick + * {
  margin-bottom: 1.5rem;
}

.fr-card__img img {
    object-fit: inherit !important;
    padding: 16px;
}
